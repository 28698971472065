<template>
    <div class="TabButton" v-on:click="handleSelection">
       <span class="text__psb">{{ title }}</span>
       <img src="@/assets/common/arrow-right.svg" />
    </div>
</template>
<script>
export default {
  props: {
    title: String,
    tab: String,
  },
  methods: {
    handleSelection() {
      this.$emit('handleTabSelection', this.tab);
    },
  },
};
</script>
<style lang="sass" scoped>
  @import './TabButton.sass'
</style>
