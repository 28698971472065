import { render, staticRenderFns } from "./TabButton.vue?vue&type=template&id=6db93538&scoped=true&"
import script from "./TabButton.vue?vue&type=script&lang=js&"
export * from "./TabButton.vue?vue&type=script&lang=js&"
import style0 from "./TabButton.vue?vue&type=style&index=0&id=6db93538&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db93538",
  null
  
)

export default component.exports